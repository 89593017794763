import { useMsal } from '@azure/msal-react';
import { log } from '../telemetry/logger';

export interface IMsalUtils {
  getAccesstoken(scopes: string[]): Promise<string>;
}

export const useMsalUtils = (): IMsalUtils => {
  const { instance } = useMsal();

  /**
   * @description This will try to fetch an accesstoken and
   * fallback to a redirect on failure.
   */
  const getAccesstoken = async (scopes: string[]): Promise<string> => {
    try {
      const result = await instance.acquireTokenSilent({
        scopes,
      });
      return result.accessToken;
    } catch (error) {
      log.debug(
        'getAccesstoken - error while fetching token, user will be redirected.',
        { error, scopes },
      );
      await instance.acquireTokenRedirect({
        scopes,
      });
      return ''; // should not return because client will redirected
    }
  };

  return {
    getAccesstoken,
  };
};
