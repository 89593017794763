import React, { useEffect, useState } from "react";
import "./SelectionPage.css";
import MainButton from "Components/MainButton/MainButton";
import { Typography } from "@mui/material";
import { colors } from "globalColors";
import { useLocation, useNavigate } from "react-router-dom";
import { useApi } from "services/useApi/useApi";
import LoadingSpinner from "Components/LoadingSpinner/loadingSpinner";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export const SelectionPage: React.FC = () => {
  const { getValidateArea } = useApi();
  const navigate = useNavigate();
  const search = useLocation().search;

  const [loading, setLoading] = useState<boolean>(false);

  const params = new URLSearchParams(search);
  let locationKeyFromUser: string | null = null;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);

    if (window.location.href.includes("?")) {
      const newUrl = window.location.href.split("?")[0];
      window.history.replaceState({}, document.title, newUrl);
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  params.forEach((value, key) => {
    if (key.toLowerCase() === "key") {
      locationKeyFromUser = value;
    }
  });

  useEffect(() => {
    const isValidLocationKey = (str: string | null): boolean => {
      if (!str) {
        return false; // Handle null or undefined case
      }

      // Check if the format is three letters
      const regex = /^[a-z]{3}/;
      return regex.test(str);
    };

    const handleGetCustomersCall = async () => {
      try {
        setLoading(true);
        if (!locationKeyFromUser) {
          return;
        }
        const data = await getValidateArea(locationKeyFromUser);

        if (data) {
          navigate("/selection/office", { state: data });
        }
      } catch (error: any) {
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    };

    const validLocationKey = isValidLocationKey(locationKeyFromUser);

    if (locationKeyFromUser && !validLocationKey) {
      setOpenSnackbar(true);
    } else if (validLocationKey) {
      handleGetCustomersCall();
    }
  }, [locationKeyFromUser]);

  const handleOfficeEquipmentClick = () => {
    const routeURL = `locationKey`;
    navigate(routeURL);
  };

  const handlePersonalEquipmentClick = () => {
    const routeURL = `personal`;
    navigate(routeURL);
  };

  return (
    <div className="homePageContentBox">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="textAndButtonContainerSelectionPage">
          <Snackbar
            className="snackBar"
            open={openSnackbar}
            onClose={handleCloseSnackbar}
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SnackbarContent
              sx={{
                flexWrap: "nowrap",
                backgroundColor: "#18272F",
                color: "white",
                margin: "12px",
              }}
              message="There was an error retrieving the QR code, enter the location key from the sticker.
              "
              action={action}
            />
          </Snackbar>

          <div className="txtBox">
            <Typography className="whatText" color={colors.White}>
              HELP US LOCATE
            </Typography>
            <Typography className="withText" color={colors.RustyRed300}>
              YOUR ISSUE
            </Typography>
          </div>
          <div className="buttonContainer">
            <MainButton
              buttonText="USE LOCATION KEY"
              onClick={handleOfficeEquipmentClick}
            />
            <MainButton
              buttonText="I HAVE NO LOCATION KEY"
              onClick={handlePersonalEquipmentClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectionPage;
