import "./GoBackButton.css";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";

const GoBackButton = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/selection");
  };

  return (
    <div className="goBackButton">
      <Button color="primary" size="large" onClick={handleGoBack}>
        <KeyboardArrowLeftIcon />
        <Typography>GO BACK </Typography>
      </Button>
    </div>
  );
};

export default GoBackButton;
