import React from "react";
import LoadingSpinner from "assets/LoadingSpinner.json";
import { Player } from "@lottiefiles/react-lottie-player";
import "./loadingSpinner.css";

export default function loadingSpinner() {
  return (
    <div className="spinnerContainer">
      <Player className="animationPlayer" src={LoadingSpinner} loop autoplay />
    </div>
  );
}
