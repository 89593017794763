/* eslint-disable react/prop-types */
import React from "react";
import { Typography } from "@mui/material";
import { colors } from "globalColors";
import "./AuthError.css";
import MainButton from "Components/MainButton/MainButton";
import { useNavigate } from "react-router-dom";

export const AuthError: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="page404ContentBox">
      <div className="txtAndButtonContainer">
        <div>
          <Typography className="smallTxt" color={colors.White}>
            SOMETHING WENT
          </Typography>
          <Typography className="largeText" color={colors.RustyRed300}>
            WRONG
          </Typography>
          <Typography color={colors.White}>
            Refresh the page to try again, go back to the log in page, or send your issue in an email to{" "}
            <button className="underlineText">support@arribatec.com</button>
          </Typography>
        </div>
        <div className="buttonContainer">
        <MainButton
            buttonText="LOGIN PAGE"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AuthError;
