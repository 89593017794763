import axios, { ResponseType } from "axios";
import { appSettings } from "config/appSettings";
import { IGetValidateModels } from "services/useApi/models/IGetValidateModels";
import { IGetSubmitTicket } from "./models/IGetSubmitTicket";
import { useMsalUtils } from "auth/useMsalUtils";

export interface IUseApi {
  getValidateArea(areaCode?: string | null): Promise<IGetValidateModels>;
  submitTicket(
    UserEmail?: string | null,
    TicketTitle?: string | null,
    TicketContent?: string | null,
    TicketAttachments?: File[] | null,
    areaCode?: string | null
  ): Promise<IGetSubmitTicket>;
}

interface IAxiosConfig {
  baseURL: string;
  responseType: ResponseType;
}

const axiosConfig: IAxiosConfig = {
  baseURL: `${appSettings.api.host}/v1`,
  responseType: "json",
};

export const useApi = (): IUseApi => {
  const { getAccesstoken } = useMsalUtils();

  const getValidateArea = async (
    AreaCode = ""
  ): Promise<IGetValidateModels> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);

    let routeUrl = `/Vaktmestern/area/validate?AreaCode=${AreaCode}`;

    const response = await axios.get<IGetValidateModels>(routeUrl, {
      ...axiosConfig,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  const submitTicket = async (
    UserEmail = "",
    TicketTitle = "",
    TicketContent = "",
    TicketAttachments: File[],
    AreaCode = ""
  ): Promise<IGetSubmitTicket> => {
    const token = await getAccesstoken(appSettings.auth.apiScopes);

    let routeUrl = `/Vaktmestern/ticket/create`;

    const formData = new FormData();
    formData.append("UserEmail", UserEmail);
    formData.append("TicketTitle", TicketTitle);
    formData.append("TicketContent", TicketContent);
    formData.append("areaCode", AreaCode);

    if (TicketAttachments && TicketAttachments?.length > 0) {
      TicketAttachments.forEach((image) => {
        formData.append("TicketAttachments", image);
      });
    }

    const response = await axios.post<IGetSubmitTicket>(routeUrl, formData, {
      ...axiosConfig,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  };

  return {
    getValidateArea,
    submitTicket,
  };
};
