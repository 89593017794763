import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import "./describeIssueForm.css";
import ClearIcon from "@mui/icons-material/Clear";
import { useApi } from "services/useApi/useApi";
import MainButton from "Components/MainButton/MainButton";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { ImageFile, manageUniqueFiles } from "utils/functions";

const TextFieldWithButton = ({
  loading,
  setLoading,
  areaCode,
}: {
  loading: boolean;
  setLoading: (val: boolean) => void;
  areaCode?: string;
}) => {
  const { submitTicket } = useApi();
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [subject, setSubject] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [fileList, setFileList] = useState<ImageFile[]>([]);
  const [subjectError, setSubjectError] = useState<string>("");
  const [descriptionError, setDesriptionError] = useState<string>("");
  const [attachmentError, setAttachmentError] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");

  const [loadingImages, setLoadingImages] = useState<boolean>(false);

  const maxFileCount = 5;

  useEffect(() => {
    const userEmailFromInstance = instance.getActiveAccount()?.username;
    setUserEmail(userEmailFromInstance || "");
  }, [instance]);

  const submitTicketAction = async () => {
    try {
      if (userEmail && subject && description) {
        setLoading(true);
        const files: File[] = fileList.map(
          (imageFile) => imageFile.file as File
        );
        const data = await submitTicket(
          userEmail,
          subject,
          description,
          files,
          areaCode
        );
        if (data?.haloTicketId) {
          navigate(`/confirmation`, {
            state: {
              haloTicketId: data.haloTicketId,
            },
          });
        } else {
          navigate(`/confirmation`, {
            state: {
              haloTicketId: data.haloTicketId,
            },
          });
        }
      } else if (subject === "" && description === "") {
        setSubjectError("Please fill in the form");
        setDesriptionError("Please fill in the form");
      } else {
        if (subject === "") {
          setSubjectError("Please fill in the subject");
        } else {
          setSubjectError("");
        }

        if (description === "") {
          setDesriptionError("Please fill in the description");
        } else {
          setDesriptionError("");
        }
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const handleSubjectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value);
  };

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDescription(event.target.value);
  };

  const addImageAction = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const imageFiles = Array.from(event.target.files || []);
    const currentFileCount = (fileList && fileList.length) || 0;

    if (imageFiles && imageFiles.length > maxFileCount) {
      setAttachmentError("The maximum ammount of files is 5");
      return;
    } else if (
      imageFiles &&
      currentFileCount + imageFiles.length > maxFileCount
    ) {
      setAttachmentError("The maximum ammount of files is 5");
      return;
    } else if (imageFiles) {
      setLoadingImages(true);
      try {
        for (const file of imageFiles) {
          const existingFile = fileList.find((f) => f.name === file.name);
          if (existingFile) {
            setAttachmentError("File with the same name already exists.");
            setLoadingImages(false);
            return;
          }
        }

        const renamedFilesMap = imageFiles.reduce((map, file, index) => {
          const fileType = file.type.split("/").pop(); // Get the file extension
          const fileName = `image${currentFileCount + index + 1}.${fileType}`; // Construct the new filename
          const renamedFile = new File([file], fileName, { type: file.type }); // Create a new File object with the updated name
          map.set(file, renamedFile); // Store the original file and its renamed version in the map
          return map;
        }, new Map());

        const updatedFiles = await manageUniqueFiles(
          Array.from(renamedFilesMap.values()),
          fileList,
          maxFileCount
        );
        setFileList(updatedFiles);
        setAttachmentError("");
      } catch (error) {
      } finally {
        setLoadingImages(false);
      }
      // reset file
      event.target.value = "";
    }
  };

  const removeFile = (id: number) => {
    const updatedFileList =
      fileList && fileList.filter((file) => file && file.id !== id);
    setFileList(updatedFileList);
  };

  return (
    <div className="formContainer">
      <div>
        <div className="textFieldContainer">
          <TextField
            color="secondary"
            onChange={handleSubjectChange}
            id="filled-basic"
            label="SUBJECT (REQUIRED)"
            variant="filled"
            required={true}
            value={subject}
            error={!!subjectError}
            helperText={subjectError}
          />
          <TextField
            color="secondary"
            onChange={handleDescriptionChange}
            id="filled-basic"
            label="DESCRIBE ISSUE (REQUIRED)"
            variant="filled"
            multiline
            maxRows="10"
            minRows="5"
            required={true}
            value={description}
            helperText={descriptionError}
            error={!!descriptionError}
          />
        </div>

        <div className="attachmentListContainer" id="fileList">
          {fileList &&
            fileList.map((file) => (
              <div className="attachmentList" key={file && file.id}>
                {file && file.name}
                <button
                  className="removeAttachmentButton"
                  onClick={file?.id ? () => removeFile(file.id) : () => {}}
                >
                  <ClearIcon />
                </button>
              </div>
            ))}
        </div>
        <div className="labelContainer">
          <label>
            <div className="addAttachment">
              <p>
                ADD ATTACHMENT({fileList?.length}/{maxFileCount})
              </p>
            </div>
            <input
              className="attachmentInput"
              type="file"
              id="myFile"
              name="filename"
              multiple
              max="5"
              accept="image/jpg, image/jpeg, image/png, image/heic"
              onChange={addImageAction}
            />
          </label>
        </div>
        <div className="attachmentErrorText">
          <p>{attachmentError}</p>
        </div>

        <div className="submitIssueButton">
          <MainButton
            buttonText="SUBMIT ISSUE"
            onClick={submitTicketAction}
            loading={loading || loadingImages}
          />
        </div>
      </div>
    </div>
  );
};

export default TextFieldWithButton;
