import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { msalConfig } from "auth/msalConfig";
import { PublicClientApplication } from "@azure/msal-browser";

PublicClientApplication.createPublicClientApplication(msalConfig)
  .then(pca => {
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <App msalInstance={pca} />
      </React.StrictMode>,
    )
  })
  .catch((error)=>{
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>
        <>
          {error}
        </>
      </React.StrictMode>,
    )
  });