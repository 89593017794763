/* eslint-disable react/prop-types */
import React from "react";
import { Typography } from "@mui/material";
import { colors } from "globalColors";
import "./Page404.css";

export const Page404: React.FC = () => {
  const handleEmailButtonClick = () => {
    const recipientEmail = "helpdesk@arribatec.com";

    const mailtoLink = `mailto:${recipientEmail}`;

    // Open the user's default email client
    window.location.href = mailtoLink;
  };

  return (
    <div className="page404ContentBox">
      <div className="txtAndButtonContainer">
        <div>
          <Typography className="smallTxt" color={colors.White}>
            SOMETHING WENT
          </Typography>
          <Typography className="largeText" color={colors.RustyRed300}>
            WRONG
          </Typography>
          <Typography color={colors.White}>
            Refresh the page to try again, or send your issue in an email to{" "}
            <button className="underlineText" onClick={handleEmailButtonClick}>
              helpdesk@arribatec.com
            </button>
          </Typography>
        </div>
        <div className="buttonContainer"></div>
      </div>
    </div>
  );
};

export default Page404;
