import { Route, Routes } from "react-router-dom";
import RouteGuard from "auth/RouteGuard";
import AuthPage from "AuthPage";
import Page404 from "./Page404/Page404";
import SelectionPage from "./Home/SelectionPage";
import LoginPage from "./LandingPage/LoginPage";
import PersonalEquipment from "./PersonalEquipment/PersonalEquipment";
import LocationKeyPage from "./LocationKeyPage/LocationKeyPage";
import ConfirmationPage from "./ConfirmationPage/ConfirmationPage";
import OfficeEquipment from "./OfficeEquipment/OfficeEquipment";

export const Pages = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route
        path="/selection"
        element={
          <RouteGuard>
            <SelectionPage />
          </RouteGuard>
        }
      />
      <Route
        path="selection/personal"
        element={
          <RouteGuard>
            <PersonalEquipment />
          </RouteGuard>
        }
      />
      <Route
        path="/selection/office"
        element={
          <RouteGuard>
            <OfficeEquipment />
          </RouteGuard>
        }
      />

      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/selection/locationKey"
        element={
          <RouteGuard>
            <LocationKeyPage />
          </RouteGuard>
        }
      />
      <Route
        path="/confirmation"
        element={
          <RouteGuard>
            <ConfirmationPage />
          </RouteGuard>
        }
      />
      <Route path="/logout" element={<LoginPage />} />
      <Route path="/auth" element={<AuthPage />} />
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

export default Pages;
