import React, { useRef, useEffect, useState } from "react";
import "./styles.css";

interface LazyImageProps {
  lowResSrc: string;
  highResSrc: string;
  alt: string;
}

const LazyImage: React.FC<LazyImageProps> = ({
  lowResSrc,
  highResSrc,
  alt,
}) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  return (
    <img
      ref={imageRef}
      src={isVisible ? highResSrc : lowResSrc}
      alt={alt}
      className={isVisible ? "visible lazy-image" : "lazy-image"}
    />
  );
};

export default LazyImage;
