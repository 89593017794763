import "./LoginPage.css";
import { useEffect } from "react";
import offixLogo from "Images/OffixLogo.svg";
import MainButton from "Components/MainButton/MainButton";
import appSettings from "config/appSettings";
import { useMsal } from "@azure/msal-react";
import { Typography } from "@mui/material";
import { colors } from "globalColors";
import { UseActiveUser } from "hooks/useActiveUser";
import { useNavigate } from "react-router-dom";

export const LandingPage: React.FC = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const activeUser = UseActiveUser();

  useEffect(() => {
    if (activeUser) {
      navigate("/selection");
    }
  });
  const login = () => {
    localStorage.clear();

    instance
      .loginRedirect({
        authority: appSettings.auth.authority,
        scopes: appSettings.auth.loginScopes,
        redirectUri: appSettings.auth.redirectUrl,
        redirectStartPage: "/selection",
      })
      .catch();
  };

  return (
    <>
      <div className="contentBox">
        <div className="logoContainer">
          <img src={offixLogo} alt="" className="offixLogo" />
        </div>
        <div className="txtAndButtonContainer">
          <div>
            <Typography className="loginText" color={colors.White}>
              LOG IN TO REPORT AN
            </Typography>
            <Typography className="issueText" color={colors.RustyRed300}>
              ISSUE
            </Typography>
          </div>
          <div>
            <MainButton buttonText="Log in" onClick={login} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
