import React from "react";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import "./BurgerMenu.css";
import BurgerIcon from "assets/icons/BurgerIcon2.svg";
import { useMsal } from "@azure/msal-react";
import appSettings from "config/appSettings";
import { colors } from "globalColors";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logOut = () => {
    // force clear
    localStorage.clear();

    instance
      .logoutRedirect({
        authority: appSettings.auth.authority,
      })
      .catch();
  };
  const { instance } = useMsal();

  return (
    <React.Fragment>
      <Box className="burgerMenuContainer">
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="large"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <img src={BurgerIcon} alt="BurgerMenuIcon"></img>
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
      >
        <Divider color={colors.DarkBlue} />{" "}
        <MenuItem
          sx={{
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <Typography>{instance.getActiveAccount()?.name}</Typography>
          <Typography>{instance.getActiveAccount()?.username}</Typography>
        </MenuItem>
        <Divider color={colors.DarkBlue} />
        <MenuItem onClick={logOut}>
          <LogoutIcon />
          Sign out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
