import Compressor from "compressorjs";

export type ImageFile = {
  id: number;
  name: string;
  file: File;
};

const compressImage = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6,
      maxWidth: 1400,
      success(result) {
        if (result instanceof File) {
          resolve(result);
        } else if (result instanceof Blob) {
          const compressedFile = new File([result], file.name, {
            type: file.type,
          });
          resolve(compressedFile);
        } else {
          reject(new Error("Compressed result is not a File or Blob"));
        }
      },
      error(err) {
        reject(err);
      },
    });
  });
};

const shortenFileName = (file: File): string => {
  const maxLength = 25;
  const ellipsis = "...";
  const adjustedMaxLength = maxLength - ellipsis.length;

  if (file.name.length > maxLength) {
    const truncatedName = file.name.substring(0, adjustedMaxLength);
    return `${truncatedName}${ellipsis}`;
  }

  return file.name;
};

const handleFileChange = async (
  file: File,
  setError?: (val: string) => void
): Promise<File | undefined> => {
  if (file) {
    try {
      const compressedFile = await compressImage(file);
      return compressedFile;
    } catch {
      setError?.("Error while uploading image");
    }
  }
  setError?.("Error while uploading image");
  return undefined;
};

const manageUniqueFiles = async (
  newFiles: File[],
  existingFiles: ImageFile[],
  maxFileCount: number,
  setError?: (val: string) => void
): Promise<ImageFile[]> => {
  const uniqueFiles: ImageFile[] = [...existingFiles];

  for (const file of newFiles) {
    if (uniqueFiles.length + 1 > maxFileCount) {
      return uniqueFiles;
    }

    const existingFile = uniqueFiles.find((f) => f.name === file.name);

    if (!existingFile) {
      const compressedFile = await handleFileChange(file);

      if (compressedFile) {
        const uniqueId = Math.random();
        const shortenedName = shortenFileName(compressedFile);
        const customFile: ImageFile = {
          id: uniqueId,
          name: shortenedName,
          file: compressedFile,
        };
        uniqueFiles.push(customFile);
      } else {
        setError?.("Image compression failed.");
      }
    } else {
      setError?.(
        "File already exists or you've reached the maximum file limit."
      );
    }
  }

  return uniqueFiles;
};

export { handleFileChange, manageUniqueFiles };
