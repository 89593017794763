import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { darkTheme } from "colorThemes";

const MainButton = ({
  buttonText,
  onClick,
  loading,
}: {
  buttonText: string;
  onClick: () => void;
  loading?: boolean;
}) => {
  const rustyRedColor = {
    color: darkTheme.primary.main,
  };
  const buttonStyle = {
    fontSize: "15px",
    fontWeight: "500",
    width: "100%",
    height: "50px",
    border: "solid",
    borderWidth: "3px",
    color: darkTheme.primary.main,
    borderColor: darkTheme.primary.main,
  };
  return (
    <>
      <Button
        variant="outlined"
        size="large"
        style={buttonStyle}
        onClick={onClick}
        disabled={loading}
      >
        {loading ? (
          <CircularProgress style={rustyRedColor} size="1.5rem" />
        ) : (
          <Typography>{buttonText}</Typography>
        )}
      </Button>
    </>
  );
};

export default MainButton;
