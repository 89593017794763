import { createTheme } from "@mui/material/styles";
import { darkTheme } from "./colorThemes";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: { ...darkTheme, mode: "dark" },
  spacing: [0, 8, 16, 32, 64], // husk og endre ettersom, til riktig tall.
  components: {
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: "100%",
        }),
      },
    },
  },
});

export default theme;
