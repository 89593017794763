import { useState } from "react";
import MainButton from "Components/MainButton/MainButton";
import { Typography } from "@mui/material";
import { colors } from "globalColors";
import { useApi } from "services/useApi/useApi";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import GoBackButton from "Components/GoBackButton/GoBackButton";
import "./LocationKeyPage.css";

const LocationKeyPage = () => {
  const { getValidateArea } = useApi();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [locationKey, setLocationKey] = useState<string>("");

  const navigate = useNavigate();

  const handleGetCustomersCall = async () => {
    try {
      setLoading(true);

      if (!locationKey) {
        setError("Enter Location key");
        return;
      }
      setError("");
      const data = await getValidateArea(locationKey);

      if (data?.areaName && data?.locationName && data?.areaCode) {
        navigate("/selection/office", { state: data });
      } else {
        setError(
          "Invalid location key. Make sure you have typed it exactly as it appears on the sticker."
        );
      }
    } catch (err) {
      setError(
        "Invalid location key. Make sure you have typed it exactly as it appears on the sticker."
      );
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  };

  const handleLocationKeyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;

    setLocationKey(inputValue);

    let errorMessage = "";

    for (let i = 0; i < inputValue.length; i++) {
      const char = inputValue[i];
      if (!/[0-9a-zA-Z-]*$/.test(char)) {
        errorMessage =
          "Invalid location key. Make sure you have typed it exactly as it appears on the sticker.";
        break;
      }
    }

    if (inputValue.length > 9 && !errorMessage) {
      errorMessage =
        "Invalid location key. Make sure you have typed it exactly as it appears on the sticker.";
    }
    setError(errorMessage);
  };

  const handleTextFieldEnter = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleGetCustomersCall();
    }
  };

  return (
    <div className="locationKeyContentBox">
      <div className="txtAndButtonContainer">
        <GoBackButton></GoBackButton>
        <div>
          <Typography className="keyText" color={colors.White}>
            ENTER THE LOCATION KEY FROM THE
          </Typography>
          <Typography className="codeText" color={colors.RustyRed300}>
            QR CODE
          </Typography>
          <Typography className="codeText" color={colors.RustyRed300}>
            STICKER
          </Typography>
        </div>
        <TextField
          color="secondary"
          id="filled-basic"
          label="ENTER LOCATION KEY(REQUIRED)"
          variant="filled"
          value={locationKey}
          onChange={handleLocationKeyChange}
          error={!!error}
          helperText={error}
          onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) =>
            handleTextFieldEnter(event)
          }
        />
        <div className="buttonContainer">
          <MainButton
            buttonText="SUBMIT LOCATION KEY"
            onClick={handleGetCustomersCall}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default LocationKeyPage;
