import "./OfficeEquipment.css";
import Form from "Components/DescribeIssueForm/describeIssueForm";
import { Typography } from "@mui/material";
import { colors } from "globalColors";
import GoBackButton from "Components/GoBackButton/GoBackButton";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../Components/LoadingSpinner/loadingSpinner";

export default function OfficeEquipment() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const handleRemoveLocationClick = () => {
    const routeURL = `/selection/personal`;
    navigate(routeURL);
  };

  useEffect(() => {
    if (
      !location?.state?.locationName ||
      !location?.state?.areaName ||
      !location?.state?.areaType
    ) {
      const routeURL = `/selection/personal`;
      navigate(routeURL);
    }
  }, [location]);

  return (
    <div className="personalEquipmentContainer">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="personalEquipmentContent">
          <div className="textContainer">
            <GoBackButton />
            <div className="describeIssueText">
              <Typography className="describeText">DESCRIBE YOUR</Typography>
              <Typography className="issueText" color={colors.RustyRed300}>
                ISSUE
              </Typography>
              <div className="uppercaseText">
                <Typography>{location?.state?.locationName}</Typography>
                <Typography>
                  {location?.state?.areaName} - {location?.state?.areaType}
                </Typography>
                <div>
                  <Typography
                    className="removeLocationButton"
                    onClick={handleRemoveLocationClick}
                  >
                    REMOVE LOCATION
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <Form
            loading={loading}
            setLoading={setLoading}
            areaCode={location?.state?.areaCode}
          />
        </div>
      )}
    </div>
  );
}
