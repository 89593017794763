import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import "./PageLayout.css";
import bgImage from "../Images/bg.png";
import bgBlurImage from "../Images/Blurred-bg.png";
import LazyImage from "Components/LazyLoadImage/LazyImage";
import Header from "Components/Header/Header";
import { UseActiveUser } from "hooks/useActiveUser";

export const PageLayout = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const activeUser = UseActiveUser();

  const isLoginPage = location.pathname === "/";

  return (
    <div className="pageLayout">
      {activeUser && <Header />}
      <div className="pageLayoutContainer">
        <div className="bgContainer">
          <LazyImage lowResSrc={bgBlurImage} highResSrc={bgImage} alt="" />
        </div>
        <div className="pageSizeContainer">
          <div
            className={
              isLoginPage ? "contentContainerNoBG" : "contentContainer"
            }
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
