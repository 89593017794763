/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  ITelemetryItem,
  Snippet,
} from "@microsoft/applicationinsights-web";
import appSettings from "config/appSettings";

const telemetryInitializer = (envelope: ITelemetryItem): boolean | void => {
  envelope.data!["appBuildId"] = appSettings.build.buildId;
  envelope.data!["appBuildName"] = appSettings.build.buildNumber;
  envelope.data!["appReleaseName"] = appSettings.release.releaseName;
};

export const reactPlugin = new ReactPlugin();
export const appInsightsConfig: Snippet = {
  config: {
    connectionString: appSettings.appInsights.connectionString,
    enableAutoRouteTracking: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    extensions: [reactPlugin],
    enableAjaxErrorStatusText: true,
    autoTrackPageVisitTime: true,
    disableDataLossAnalysis: false,
  },
};

export const appInsights = new ApplicationInsights(appInsightsConfig);

export const initializeApplicationInsights = (): ReactPlugin => {
  appInsights.addTelemetryInitializer(telemetryInitializer);
  appInsights.loadAppInsights();
  appInsights.context.application.ver = appSettings.build.buildNumber;
  appInsights.context.application.build = appSettings.build.buildId;
  return reactPlugin;
};

export const addUserToApplicationInsights = (userId: string) => {
  appInsights.setAuthenticatedUserContext(userId.replaceAll(/[ ,;=|]/g, ""));
};
