import "./PersonalEquipment.css";
import Form from "Components/DescribeIssueForm/describeIssueForm";
import { Typography } from "@mui/material";
import { colors } from "globalColors";
import GoBackButton from "Components/GoBackButton/GoBackButton";
import { useState } from "react";
import LoadingSpinner from "../../Components/LoadingSpinner/loadingSpinner";

export default function PersonalEquipment() {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="personalEquipmentContainer">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="personalEquipmentContent">
          <div className="textContainer">
            <GoBackButton />
            <div className="describeIssueText">
              <Typography className="describeText">DESCRIBE YOUR</Typography>
              <Typography className="issueText" color={colors.RustyRed300}>
                ISSUE
              </Typography>
            </div>
          </div>
          <Form loading={loading} setLoading={setLoading} />
        </div>
      )}
    </div>
  );
}
