/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Box } from "@mui/material";
import LoadingSpinner from "assets/LoadingSpinner.json";
import { Player } from "@lottiefiles/react-lottie-player";
import "index.css";
import { useMsal } from "@azure/msal-react";

/**
 * @description This is the page that
 * the user will be redirected to after
 * authenticating (redirect) with Azure AD.
 * It's important that this Page is unprotected
 * and doesn't trigger any Msal behaviour.
 *
 * When the user is redirected here, Msal will
 * load and pickup the auth code fragment hash
 * which it will use to fetch id-, refresh- and
 * accesstokens.
 */
export const AuthPage: React.FC = () => {
  const { instance } = useMsal();

  useEffect(()=>{
    instance
    .handleRedirectPromise()
    .then((data) => {
      if (data?.account) {
        console.log(
          'A redirect was processed and result contains an account. Setting result account to active account',
        );
        instance.setActiveAccount(data.account);
      }
    })
    .catch();
  },[])


  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <div className="spinnerConatiner">
        <Player
          className="animationPlayer"
          src={LoadingSpinner}
          loop
          autoplay
        ></Player>
      </div>
    </Box>
  );
};

export default AuthPage;
