import React, { PropsWithChildren } from "react";
import appSettings from "config/appSettings";
import {
  InteractionType,
  RedirectRequest,
} from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import AuthLoading from "AuthLoading";
import AuthError from "AuthError";

export const RouteGuard = ({
  children,
}: PropsWithChildren): React.ReactElement | null => {
  const { instance } = useMsal();
  const getLoginRequest = (): RedirectRequest => {
    return {
      authority: appSettings.auth.authority,
      scopes: appSettings.auth.loginScopes,
      account: instance.getActiveAccount() ?? undefined,
      redirectUri: appSettings.auth.redirectUrl,
    };
  };

    return (
      <MsalAuthenticationTemplate
        interactionType={
            InteractionType.Redirect
        }
        authenticationRequest={getLoginRequest()}
        loadingComponent={AuthLoading}
        errorComponent={AuthError}
      >
        {children}
      </MsalAuthenticationTemplate>
    );
};

export default RouteGuard;
