import { useEffect } from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainButton from "Components/MainButton/MainButton";
import { colors } from "globalColors";
import "./ConfirmationPage.css";
import { useLocation } from "react-router-dom";

export const ConfirmationPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location?.state?.haloTicketId) {
      navigate("*");
    }
  });

  return (
    <div className="confirmationContentBox">
      <div className="textAndButtonContainer">
        <div>
          <Typography className="thankText" color={colors.White}>
            THANK
          </Typography>
          <Typography className="youText" color={colors.RustyRed300}>
            YOU
          </Typography>
          <Typography>
            Your issue with ticket ID #{location?.state?.haloTicketId} has been
            forwarded to the Helpdesk, and a receipt will be sent to your email.
          </Typography>
          <Typography className="marginTop">
            Sit back, relax, and keep up the good work!
          </Typography>
        </div>

        <div className="buttonContainer">
          <MainButton
            buttonText="SUBMIT ANOTHER ISSUE"
            onClick={() => {
              navigate("/selection");
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage;
