import { colors } from "globalColors";

export const darkTheme = {
  primary: {
    main: colors.RustyRed300,
    light: colors.White,
    dark: colors.Grey300,
    contrastText: colors.DarkGreen900,
  },
  secondary: {
    main: colors.White,
    light: "#F5EBFF",
    dark: "#1565c0",
    contrastText: "#47008F",
  },
  error: {
    main: colors.AlertRed400,
  },
};
