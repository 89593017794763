import "./App.css";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { ClientSideNavigation } from "auth/ClientSideNavigation";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { PageLayout } from "./Components/PageLayout";
import theme from "./Theme";
import Pages from "pages/Pages";

interface IAppProps {
  msalInstance: IPublicClientApplication;
}

export const App = ({ msalInstance }: IAppProps): JSX.Element => {
  return (
    <BrowserRouter>
      <ClientSideNavigation pca={msalInstance}>
        <MsalProvider instance={msalInstance}>
          <PageLayout>
            <ThemeProvider theme={theme}>
              <CssBaseline>
                <Pages />
              </CssBaseline>
            </ThemeProvider>
          </PageLayout>
        </MsalProvider>
      </ClientSideNavigation>
    </BrowserRouter>
  );
};

export default App;
