import React from "react";
import "./Header.css";
import AccountMenu from "Components/Header/BurgerMenu/BurgerMenu";
import Logo from "Images/OffixLogoMedTekst.svg";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const navigate = useNavigate();
  return (
    <div className="headerContainer">
      <img
        src={Logo}
        alt="Logo"
        className="logo"
        onClick={() => {
          navigate("/selection");
        }}
      ></img>

      <AccountMenu />
    </div>
  );
}
